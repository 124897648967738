.welcome-container {
  width: auto;
  overflow-y: hidden;
  overflow-x: hidden;
}

.card-content {
  min-height: 70dvh;
  max-height: 70dvh;
  overflow: scroll;
  overflow-x: hidden;
}

.stepper-row {
  position: sticky;
  top: 0px;
  margin: 10px !important;
  padding: 0px !important;
  z-index: 99;
  background-color: white;
}

.stepper-row-bottom {
  /* width: 100%; */
  position: sticky;
  bottom: 0px;
  margin: 0px !important;
  /* padding: 10px !important; */
  z-index: 99;
  background-color: white;
  /* height: 5vh; */
}
.nxt-prv-btn {
  display: flex;
}

.css-1x1xx5k {
  max-width: none !important;
}

.swipe-content {
  /* min-height: 50vh; */
  background-color: aliceblue;
  border-radius: 10px;
  padding: 10px;
}

.mobile-stepper {
  width: 100% !important;
}

.company-logo-div {
  margin: 10px;
  width: 93%;
  padding: 20px;
}
.upload-main-div {
  display: flex;
  margin-left: 5px;
}
.upload-item {
  padding: 2% 2%;
}

.f-subscription-card {
  justify-content: center;
  /* min-height: 50vh; */
}
.feature-card {
  /* margin: 0% 15%; */
}
.feature-list {
  min-height: 36vh;
  max-height: 36vh;
  overflow: scroll;
  overflow-x: hidden;
  /* height: 200px;
  overflow: scroll;
  position: relative; */
}

/* .scroll-content {
  position: relative;
  animation: scroll-animation 10s linear infinite;
}

@keyframes scroll-animation {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-100%);
  }
} */

.feature-card .ant-card-body {
  min-height: 60vh;
  padding: 10px !important;
}

.feature-title {
  font-family: Arial;
  font-size: 18px;
}

.feature-description {
  font-family: Arial;
  font-size: 14px;
}

.feature-icon {
  color: green;
  background-color: lightgreen;
  padding: 5px;
  border-radius: 50%;
  margin-right: 10px;
}

.feature-info {
  font-family: Arial;
  font-size: 16px;
  /* color: green; */
  margin-bottom: 10px;
  font-weight: bold;
}

.feature-button {
  margin-top: 10px;
  border-radius: 40px;
  justify-content: center;
  text-align: center;
}

.f-title {
  width: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
}

.f-item {
  align-items: center;
  vertical-align: center;
}

.f-container {
  display: flex;
  margin: 5px 0px;
}

.f-check-description {
  text-indent: 30px;
  margin-left: 35px;
}

.popping-card {
  position: relative;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s, box-shadow 0.3s;
}

.popping-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
}
.f-button {
  width: 100%;
  text-align: center;
}
.f-button-box {
  display: flex;
}
.f-button-title {
  padding: 0px 10px;
}

.c-fields {
  /* margin-bottom: 10px !important;
  margin-top: 5px !important; */
}

.customer-form-logo {
  width: 100%;
}
