body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url("./assets/images/call-center-bg-1.jpg");
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* For WebKit-based browsers */
::-webkit-scrollbar {
  width: 1px;
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

@media (max-width: 768px) {
  .mobile-card .ant-card-body {
    /* padding: 10px !important; */
  }
  .mobile-card .ant-form {
    width: 90vw !important;
  }

  .mobile-card .swipe-content {
    width: 82vw !important;
    /* padding: 5px 7px 0px 5px !important; */
  }

  .mobile-card .card-content {
    height: 100vh;
  }
}

.ant-card-body {
  padding: 10px !important;
}


.react-tel-input .form-control {
  width: 100%;
}