.welcome-container {
  width: auto;
  overflow-y: scroll;
  overflow-x: hidden;
}

.card-content {
  /* min-height: 60vh; */
  /* max-height: 65vh; */
}

.about-us {
  background-color: #f2f2f2;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.about-us h2 {
  color: #333;
  font-size: 24px;
  margin-bottom: 5px;
}

.about-us p {
  color: #666;
  font-size: 16px;
  line-height: 1.5;
}

.sticky-top {
  position: sticky;
  top: 0px;
  background-color: white;
}

.sticky-bottom {
  position: sticky;
  bottom: 0px;
  background-color: white;
  margin-top: 10px;
  float: right;
}

.link-expired-card {
  text-align: center;
  width: 100%;
  margin-top: 25vh;
}

.thank-you-card {
  text-align: center;
  width: 100%;
  margin-top: 25vh;
}
