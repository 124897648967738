.title-word {
  animation: color-animation 4s linear infinite;
}

.title-word-1 {
  --color-1: #f61d1d;
  --color-2: #02ef71f6;
  --color-3: #ff801e;
}

.title-word-2 {
  --color-1: #f0d911;
  --color-2: #27d9ec;
  --color-3: #dd00ff;
}

.title-word-3 {
  --color-1: #000000;
  --color-2: #64a100;
  --color-3: #accfcb;
}

.title-word-4 {
  --color-1: #3d8dae;
  --color-2: #df8453;
  --color-3: #e4a9a8;
}

@keyframes color-animation {
  0% {
    color: var(--color-1);
  }
  32% {
    color: var(--color-1);
  }
  33% {
    color: var(--color-2);
  }
  65% {
    color: var(--color-2);
  }
  66% {
    color: var(--color-3);
  }
  99% {
    color: var(--color-3);
  }
  100% {
    color: var(--color-1);
  }
}

/* Here are just some visual styles. 🖌 */

.container {
  align-items: center;
  justify-content: center;
  width: auto;
  padding: 3% 3%;
  height: auto;
}

.title {
  font-family: "Montserrat", sans-serif;
  font-weight: 800;
  font-size: 5vw;
  text-transform: capitalize;
}

.fade-in {
  animation: fadeIn 2s;
  color: #ffffff;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.slide-in {
  animation: slideIn 1s;
}

@keyframes slideIn {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}

.button {
  display: inline-block;
  padding: 10px 20px;
  margin-top: 20px;
  background-color: #2c264df0;
  color: #fff;
  font-weight: bold;
  text-decoration: none;
  border-radius: 20px;
  cursor: pointer;
}

.pulse {
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.col-item {
  /* margin: 10px;
  padding: 10px; */
}

.lc-main-div {
  margin: 5px;
  align-items: center;
}

.landing-info-card {
  background: #ffffffcc !important;
}

.lc-item {
  padding: 20px;
  width: 50%;
}

.lc-item .ant-card-body {
  min-height: 50vh !important;
  padding: 10px !important;
}

.lc-sub-title {
  font-size: 20px;
}
