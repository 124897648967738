.component-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  /* background-color: #ffffffbf; */
  /* border-radius: 10px; */
  /* box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); */
  overflow: hidden;
}

.image-container {
  width: 40%;
  margin-right: 20px;
  animation: slideInImage 0.5s ease-out;
}

@keyframes slideInImage {
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.image {
  width: 90%;
  transition: transform 0.3s ease-in-out;
  border-radius: 20px;
}

.image:hover {
  transform: scale(1.1);
}

.logo-container {
  /* margin-top: -10%; */
  margin-bottom: 10%;
}

.logo {
  width: 45%;
  /* transition: transform 0.3s ease-in-out; */
  border-radius: 20px;
}

.button:hover {
  transform: scale(1.1);
}

.content-container {
  width: 60%;
  animation: slideInText 0.5s ease-out;
}

@keyframes slideInText {
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.main-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 5px;
}

.sub-title {
  font-size: 18px;
  font-weight: bold;
  color: #888;
  margin-bottom: 10px;
}

.description {
  font-size: 16px;
  line-height: 1.5;
}

.button-container {
  width: 100%;
  text-align: right;
  margin-left: -10%;
}

.button {
  display: inline-block;
  margin-top: 10px;
  padding: 10px 30px;
  background-color: #f99c44;
  color: #fff;
  border: none;
  border-radius: 20px;
  font-size: 16px;
  cursor: pointer;
  opacity: 1;
  transition: transform 0.3s ease-in-out;
  /* transform: scale(0); */
  /* animation: easeInOutAnimation 2s infinite; */
}

@keyframes easeInOutAnimation {
  /* 0% {
      opacity: 0;
      transform: scale(0);
    } */
  25% {
    opacity: 1;
    transform: scale(1.1);
  }
  75% {
    transform: scale(0.95);
  }
  /* 100% {
      opacity: 0;
      transform: scale(0);
    } */
}

@media (max-width: 768px) {
  .component-container {
    flex-direction: column;
  }

  .image-container {
    width: 100%;
    margin-right: 0;
    margin-bottom: 20px;
  }

  .main-title {
    font-size: 20px;
  }

  .sub-title {
    font-size: 16px;
  }

  .description {
    font-size: 14px;
  }
}
