.custom-upload {
    position: relative;
  }
  
  .custom-upload .ant-btn {
    position: relative;
    z-index: 1;
  }
  
  .custom-upload-overlay {
    position: absolute;
    top: 5px;
    right: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    background-color: #fff;
    border-radius: 50%;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
    opacity: 0;
    transition: opacity 0.3s;
  }
  
  .custom-upload:hover .custom-upload-overlay {
    opacity: 1;
  }
  
  .custom-upload-edit-icon {
    font-size: 14px;
  }


  /* CustomUpload.css */

.custom-upload-thumbnail {
  position: relative;
  display: inline-block;
}

.custom-upload-thumbnail img {
  width: 100%;
  height: auto;
}

.custom-upload-edit-overlay {
  position: absolute;
  top: 0;
  right: 0;
  padding: 4px;
  background-color: rgba(255, 255, 255, 0.8);
  transition: background-color 0.3s;
}

.custom-upload-edit-overlay:hover {
  background-color: rgba(255, 255, 255, 1);
}

.custom-upload-items {
  display: flex;
  flex-wrap: wrap;
  margin-top: 16px;
}

.custom-upload-item {
  width: 120px;
  margin-right: 16px;
  margin-bottom: 16px;
}
